import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function Benches() {
  const data = useStaticQuery(graphql`
    query BenchesQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "bench" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Benches"
      title="Benches Gallery | Van Gemert Memorials"
      description="Photo gallery of benches that we have made for our customers."
      urlPath="benches"
    />
  );
}
